.home {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  max-width: 90vw;
}

.home__row {
  display: flex;
  flex-wrap: wrap;
  z-index: 1;
  /*margin-left: 5px;*/
  /*margin-right: 5px;*/
}

.home__image {
  width: 100%;
  z-index: -1;
  margin-bottom: -150px;
  mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
}

.loadingText {
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  background: linear-gradient(to right, #d0e320, #62ff00, #ae6fff, #e76790) 0%;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 400%;
  /*color: linear-gradient(to right, #6420e3, #00ffeb, #ffbf00, #ff6600) 0%;*/
  animation: shine 3s linear infinite;
}
@keyframes shine {
  0% {
    background-position: 100% center;
  }
  50% {
    background-position: 200% center;
  }
  100% {
    background-position: 100% center;
  }
}

.home_my_resources {
  transform: translateX(-24.75vw);
  transition: all 0.4s;

  &:hover {
    transform: translateX(0);
    opacity: 1;
    transition: all 0.4s;
  }
}
