:root {
  color: #1b1b1b;
  background-color: #e5e5e5;
  --navbar: theme('colors.purple.700');
  --background: theme('colors.white');
  --background-secondary: theme('colors.gray.50');

  --accent-dark: theme('colors.fuchsia.900');
  --accent-medium: theme('colors.fuchsia.700');
  --accent-light: theme('colors.fuchsia.500');

  --primary-dark: theme('colors.gray.900');
  --primary-medium: theme('colors.gray.700');
  --primary-light: theme('colors.gray.500');
  transition: all 0.5s ease;
}

[data-theme='dark'] {
  color: #e5e5e5;
  background-color: #010003;
  --navbar: #351262;
  --background: #000;
  --background-secondary: #1c152b;

  --accent-dark: theme('colors.fuchsia.900');
  --accent-medium: theme('colors.fuchsia.700');
  --accent-light: theme('colors.fuchsia.500');

  --primary-dark: theme('colors.gray.300');
  --primary-medium: theme('colors.gray.200');
  --primary-light: theme('colors.gray.100');
  transition: all 0.5s ease;
}
