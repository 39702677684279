* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@tailwind base;

/* Write your own custom base styles here */

/* Start purging... */
@tailwind components;
/* Stop purging. */

/* Write your own custom component styles here */
.btn-blue {
  @apply bg-blue-500 text-white font-bold py-2 px-4 rounded;
}

/* Start purging... */
@tailwind utilities;
/* Stop purging. */

.switch {
  width: 2.5em;
  height: 1.5rem;
  background-color: rgba(255, 255, 255, 0.4);
  display: flex;
  justify-content: flex-start;
  border-radius: 50px;
  padding: 0.1rem;
  cursor: pointer;
}

.switch[data-isOn='true'] {
  justify-content: flex-end;
  background-color: rgb(17, 200, 101);
}

.handle {
  width: 1.25rem;
  height: 1.25rem;
  background-color: white;
  border-radius: 40px;
}
