.baseAuthModal {
  position: absolute;
  z-index: 30;
  right: 5%;
  left: 5%;
  width: 90%;

  @media screen and (min-width: 768px) {
    top: 10vh;
    right: 10vh;
    left: auto;
    width: 50%;
    max-width: 64rem;
  }
  @media screen and (min-width: 1024px) {
    right: 10vh;
    width: 70%;
    max-width: 32rem;
  }
}

.loginModal {
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  top: 50vh;
  @media screen and (min-width: 768px) {
    top: 10vh;
    right: 10vh;
    left: auto;
    width: 50%;
    max-width: 64rem;
  }
  @media screen and (min-width: 1024px) {
    right: 10vh;
    width: 70%;
    max-width: 32rem;
  }
  height: auto;
  border: #2c5282 5px;
  border-radius: 0.5rem;
  overflow: hidden;
  transition: 1s;
  --text-color: #afafaf;

  &:hover {
    box-shadow: #009dff7a 0 0 100px;
    transition: all 1s;
  }
}

.oauthModal {
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  height: 22vh;
  top: 25vh;
  @media screen and (min-width: 768px) {
    right: 10vh;
    left: auto;
    width: 50%;
    max-width: 64rem;
    height: 15vh;
    top: 75vh;
  }
  @media screen and (min-width: 1024px) {
    right: 10vh;
    width: 70%;
    max-width: 32rem;
  }
  border: #2c5282 5px;
  border-radius: 0.5rem;
  overflow: hidden;
  transition: 1s;
  --text-color: #afafaf;

  &:hover {
    box-shadow: #001aff7a 0 0 100px;
    transition: all 1s;
  }
}

.out {
  opacity: 0;
  /*right: -10vh;*/
  transform: skewX(30deg) translateX(150%) rotateY(90deg);
  transition: all 0.75s;
}

.loginButton:disabled {
  transition: 2s all;
  background-size: 1000%;
  background: linear-gradient(#1e3b3c, #2e533f);
}

.lm_field {
  width: 100%;
  margin: 0 auto;
  position: relative;
  border-bottom: 2px dashed var(--text-color);
  margin: 4rem auto 1rem;
  transition: 500ms;
}
.lm_label {
  color: var(--text-color);
  font-size: 1.2rem;
}
.lm_input {
  outline: none;
  border: none;
  overflow: hidden;
  margin: 0;
  width: 100%;
  padding: 0.25rem 0;
  background: none;
  color: white;
  font-size: 1.2em;
  font-weight: bold;
  transition: border 500ms;
}

.lm_input__error {
  box-shadow: #dd6b20 10px 10px 10px;
  transition: border 500ms;
}

.lm_input__error_text {
  position: absolute;
  margin-right: 0.25rem;
  right: 0;
  top: -0.75rem;
  color: #dd6b20;
  text-shadow: #9c4221 0 0 15px;
}

.lm_input:valid {
  color: yellowgreen;
}
.lm_input:invalid {
  color: orangered;
}

.lm_input.invalid {
  color: orangered;
}
/* Border animation */
div.lm_field::after {
  content: '';
  position: relative;
  display: block;
  height: 3px;
  width: 100%;
  background: #d16dff;
  transform: scaleX(0);
  transform-origin: 0%;
  opacity: 0;
  transition: all 500ms ease;
  top: 2px;
}
.lm_field:focus-within {
  border-color: transparent;
}
.lm_field:focus-within::after {
  transform: scaleX(1);
  opacity: 1;
}
/* Label animation */
.lm_label {
  z-index: -1;
  position: absolute;
  transform: translateY(-2rem);
  transform-origin: 0%;
  transition: transform 400ms;
}
.lm_field:focus-within .label,
.lm_input:not(:placeholder-shown) + .label {
  transform: scale(0.8) translateY(-5rem);
  opacity: 1;
}
/* Strength Meter */
.strength {
  display: flex;
  height: 20px;
  width: 100%;
}
.bar {
  margin-right: 5px;
  height: 100%;
  width: 25%;
  transition: box-shadow 500ms;
  box-shadow: inset 0px 20px #1f1f1f;
}
.bar-show {
  box-shadow: none;
}
.bar-1 {
  background: linear-gradient(to right, red, orangered);
}
.bar-2 {
  background: linear-gradient(to right, orangered, yellow);
}
.bar-3 {
  background: linear-gradient(to right, yellow, yellowgreen);
}
.bar-4 {
  background: linear-gradient(to right, yellowgreen, green);
}
.bar:last-child {
  margin-right: 0;
}
.strength-text {
  margin-top: 20px;
}
.lm_ul {
  list-style: none;
  margin: 10px 0;
  padding: 0;
  font-size: 0.7rem;
  text-align: left;
}
/* Buttons */
/*button {
  margin-top: 2rem;
  padding: 10px 30px;
  font-weight: bold;
  border: 2px solid greenyellow;
  color: greenyellow;
  border-radius: 100px;
  background: transparent;
  transition: all 1000ms;
}*/
/*button:disabled {
  border-color: var(--text-color);
  color: var(--text-color);
}*/
.lm_toggle-password {
  position: absolute;
  cursor: help;
  font-size: 0.8rem;
  right: 0.25rem;
  bottom: 0.5rem;
}

:root[data-theme='dark'] .lm_overlay {
  background: linear-gradient(to right, rgba(0, 0, 0, 0.3), rgba(0, 0, 19, 0.95) 60%);
}

.lm_overlay {
  background: linear-gradient(to right, rgba(0, 0, 0, 0.85) 20%, rgba(0, 0, 0, 0.95) 60%);
}
